<template>
  <header
    class="ui-header q-toolbar row no-wrap items-center container h-wrp"
  >
    <slot />
  </header>
</template>

<script>
export default {
  name: 'UiHeader',
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1128px;
}
.ui-header {
  position: relative;
  background-color: initial;
  color: #3d424a;
}
</style>
